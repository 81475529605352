import React from 'react';
import {  BsInstagram } from 'react-icons/bs';
import {  FaGithub, FaLinkedinIn, FaCodepen } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">

    <div>
      <FaGithub />
    </div>

    <div>
    <a href="https://www.linkedin.com/in/kevin-cabey-03a45510a/">
        <FaLinkedinIn />
      </a>
    </div>

    <div>
      <FaCodepen />
    </div>

    <div>
      <BsInstagram />
    </div>

  </div>
);

export default SocialMedia;