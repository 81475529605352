import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

import Pdf from '../About/KevinCabey-CV.pdf';

// const abouts = [
//   { title: 'web development', discription: 'little info here',  imgUrl: images.about01 },
//   { title: 'web design', discription: 'little info here',  imgUrl:images.about02 },
//   { title: 'ui/ux ', discription: 'little info here',  imgUrl: images.about03},
//   { title: 'marketing', discription: 'little info here',  imgUrl: images.about04 },
  
// ];

function About() {

  const [abouts, setAbouts] = useState([]);
  
  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
        });
      }, []);



  return (
    <>
      <h2 className="head-text">I Know that <span>Good Design</span> <br /> means <span>Good Business</span></h2>

 

        {/* <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
          ))}
        </div> */}

                <motion.div
                className='div__flex'
                >

                <div className="app__profiles_2">
                  <p className='p-text'>
                  After graduating in 2021 with a Master's Degree (Merit) in Digital Media Design, I spent the last few years working on developing my skills as a Front-end Web Developer and UX/UI Designing.<br/><br/>
                  I have to take a few breaks here and there, but I'm always kept my knowledge and skills up-to-date looking at and trying every new trend that comes out. I have worked on many of my personal projects, some are as you see displayed in my project and others will be coming soon.<br/><br/>
                  I have worked on a multitude of web projects using Design (Photoshop, Affinity, Figma) and Development (PHP, HTML, CSS, SASS, JS, React, Responsive Layouts).<br/><br/>
                  I am currently working for Zoopla, where in my previous role, I was creating HTML and Email templates for Real Estate Agents based on what they requested, and in my current position, I am doing Portal Feeds.<br/><br/>
                  These days, I'm enjoying life playing games, cooking, dancing, and enjoying life as it comes my way, I'm looking for a new goal and always striving to do better, life doesn't stop unless you stop.

                  </p>
                </div>

                <div className="app__profiles">
                {abouts.map((about, index) => (
                  <motion.div
                    whileInView={{ opacity: 1 }}
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.5, type: 'tween' }}
                    className="app__profile-item"
                    key={about.title + index}
                  >
                    <img src={urlFor(about.imgUrl)} alt={about.title} />
                    <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
                    <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
                  </motion.div>
                  ))}
                </div>

                <div className="app__about-btn">
                  <div className="btn__flex">
                    <button type="button" className="p-text">
                      <a href = {Pdf} download>View My CV</a>                  
                    </button>
                  </div>
                  <div className="btn__flex">
                    <button type="button" className="p-text" >
                      <a href="#contact" onClick={() => window.location.replace("/#contact")}>Let's Talk</a>
                    </button>
                  </div>                     
                </div>

                </motion.div>
                

    </>

  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);